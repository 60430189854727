import React, {Fragment, useEffect, useRef, useState} from "react";
import {Image} from "../../components/Image/Image";
import {CSSTransition} from "react-transition-group";
import {Link, NavLink} from "react-router-dom";
import {paths} from "../../utils/paths";
import {translations} from "../../utils/translations";
import {LinkWrapper} from "../../components/LinkWrapper/LinkWrapper";

export const HomeView = props => {

    const containerHeight = props.height / 3;
    const containerWidth = props.width / 2;

    const offsetTop = props.height * .1666 + containerHeight * .75;
    const index = Math.floor(
        (props.scrollY + offsetTop) / (containerHeight * 1.5)
    ) - 1;

    return (
        <HomeViewContent
            {...props}
            scrollY={null}
            index={index}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
        />
    );
}


const HomeViewContent = React.memo(props => {

    const {lng} = props.match.params;

    let [activeEntryId, setActiveEntryId] = useState();

    return (
        <div className="HomeView">

            <TitleDisplay
                {...{...props, activeEntryId}}
            />

            <HomeVideo
                {...props}
            />
            <HomeImageDisplay
                {...{...props, setActiveEntryId}}
            />

            <div className="HomeBottom meta-small">

                <nav className="HomeNav">
                    <NavLink
                        to={`/${lng}/${paths.projects.productions[lng]}`}
                        className="HomeNav-Item first"
                    >
                        {translations.productions[lng]}
                    </NavLink>

                    <NavLink
                        to={`/${lng}/${paths.projects.moreProjects[lng]}`}
                        className="HomeNav-Item"
                    >
                        {translations.moreProjects[lng]}
                    </NavLink>

                    <NavLink
                        to={`/${lng}/${paths.projects.calendar[lng]}`}
                        className="HomeNav-Item"
                    >
                        {translations.calendar[lng]}
                    </NavLink>

                    <NavLink
                        to={`/${lng}/${paths.projects.archive[lng]}`}
                        className="HomeNav-Item"
                    >
                        {translations.archive[lng]}
                    </NavLink>
                </nav>

            </div>
        </div>
    )
});


const TitleDisplay = props => {

    const {entries, activeEntryId} = props;
    const {lng} = props.match.params;

    return (
        <Fragment>
            {entries.map((entry, loopIndex) =>
                <CSSTransition
                    key={loopIndex}
                    in={activeEntryId === entry.id}
                    classNames={"home-title-fade"}
                    timeout={300}
                    unmountOnExit
                    mountOnEnter
                >
                    <div className="HomeTitleDisplay">
                        <div className="HomeTitle">
                            <Link to={`${lng}/${entry.uri}`}>
                                {entry.title}
                            </Link>
                        </div>
                    </div>
                </CSSTransition>
            )}
        </Fragment>
    )
}


const HomeImageDisplay = (props) => {

    const {entries, index} = props;
    const slides = entries;

    return (
        <div className="HomeImageDisplay">
            {
                slides.map(
                    (entry, loopIndex) =>
                        <HomeImage
                            {...{...props, entry, loopIndex, lastIndex: slides.length-1}}
                            key={entry.id}
                        />
                )
            }
        </div>
    )
}


const HomeImage = (props) => {
    const {lng} = props.match.params;
    const {entry, containerHeight, containerWidth, setActiveEntryId,loopIndex,lastIndex} = props;


    const [viewState, setViewState] = useState(false);
    const boxRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (nodes) => {
                nodes.forEach(node => {
                    if (node.isIntersecting) {
                        setViewState("visible")
                        setActiveEntryId(entry.id)
                    } else {
                        const position = node.boundingClientRect.bottom > window.innerHeight * 0.5 ? "before" : "after";
                        setViewState(position)
                        if(loopIndex === 0 && position ==="before" ){
                            setActiveEntryId(undefined)
                        }
                        if(loopIndex === lastIndex  && position ==="after" ){
                            setActiveEntryId(undefined)
                        }
                    }
                });
            },
            {
                rootMargin: `-${window.innerHeight * 0.5}px 0px -${window.innerHeight * 0.5}px 0px`
            }
        );

        if (boxRef.current) {
            observer.observe(boxRef.current);
        }

        // Clean up the observer when the component unmounts
        return () => {
            if (boxRef.current) {
                observer.disconnect();
            }
        };
    }, []);


    return (
        <div
            className={`
            HomeImage 
        `}
            style={{
                height: containerHeight * 1.5,
                width: containerWidth,
            }}
        >


            <div style={{
                paddingTop: containerHeight * .25,
                paddingBottom: containerHeight * .25,
            }}
                 ref={boxRef}

            >
                <div className={`
                    HomeTranslate
                    HomeTranslate--${viewState}
                 `}>
                    <LinkWrapper to={entry.uri ? `/${lng}/${entry.uri}` : null} intern>
                        {entry.coverPhoto.map(img => <Image key={img.url} {...img} />)}
                    </LinkWrapper>
                </div>
            </div>
        </div>)
}


const HomeVideo = (props) => {

    const iframeRef = useRef(null);
    const [isMuted, setIsMuted] = useState(true);


    const toggleMute = () => {
        const iframe = iframeRef.current;
        const player = new window.Vimeo.Player(iframe);
        if (isMuted) {
            player.setVolume(1);
        } else {
            player.setVolume(0);
        }
        setIsMuted(!isMuted);
    };

    return (
        <div className="HomeVideo">
            <div className="HomeVideo--Inner">

                <div className="HomeVideo--MuteToggleBtnWrapper">
                    <button
                        className={"HomeVideo--MuteToggleBtn"}
                        onClick={toggleMute}
                    >
                        {isMuted ?
                            <svg width="256"
                                 height="256"
                                 viewBox="0 0 256 256"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M136.492 128.003V68.3906L106.883 98H58.0703V158H106.878L136.492 187.615V128.003Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M162.64 83.4615C174.039 94.86 181.09 110.607 181.09 128.002C181.09 145.409 174.029 161.166 162.615 172.567L168.268 178.227C181.132 165.379 189.09 147.619 189.09 128.002C189.09 108.398 181.144 90.6508 168.297 77.8047L162.64 83.4615Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M179.371 66.7311C195.051 82.4114 204.749 104.073 204.749 128.001C204.749 151.947 195.036 173.624 179.333 189.308L184.987 194.968C202.139 177.837 212.749 154.158 212.749 128.001C212.749 101.864 202.156 78.2022 185.028 61.0742L179.371 66.7311Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M145.909 100.192C153.025 107.308 157.427 117.139 157.427 127.998C157.427 138.866 153.018 148.704 145.892 155.822L151.545 161.482C160.121 152.916 165.427 141.077 165.427 127.998C165.427 114.93 160.13 103.099 151.566 94.5352L145.909 100.192Z"
                                      fill="currentColor"/>
                            </svg>
                            :
                            <svg width="256" height="256" viewBox="0 0 256 256" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M167.211 142.016V128.003V68.3906L137.602 98H123.195L167.211 142.016ZM89.794 98H88.7891V158H137.596L167.211 187.615V175.417L89.794 98Z"
                                      fill="black"/>
                                <line x1="75.3167" y1="66.8825" x2="191.891" y2="183.457" stroke="currentColor"
                                      stroke-width="8"/>
                            </svg>
                        }
                    </button>
                </div>


                <iframe
                    src={`https://player.vimeo.com/video/${props?.homeEntry?.coverVideo}?autoplay=1&color=fdfdfe&title=0&byline=0&portrait=0&background=1&muted=1`}
                    title={props?.homeEntry?.coverVideo}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    ref={iframeRef}
                />

            </div>
        </div>
    )
}