import React, {useEffect} from 'react';
import {gql, useQuery} from '@apollo/client';
import {decidePageId} from "../../utils/decidePageId";
import {HomeView} from "./HomeView";
import {Loader} from "../../components/Loader/Loader";
import {WindowContext} from "../../utils/WindowContext";


const QUERY = gql`
    query( $siteId:[QueryArgument] ) {
        entries( section:"productions", siteId:$siteId , type: "productionCurrent" ){
            title
            id
            slug
            uri
            ... on productions_productionCurrent_Entry{
                coverPhoto {
                    id
                    url @transform(handle:"carouselImage")
                    width
                    height
                }
            }
        }
        homeEntry: entry(section:"homepage"){
            title
            id
            slug
            typeHandle
            ... on homepage_homepage_Entry{
                coverVideo
            }
        }
    }
`;


export const Home = props => {
    return (
        <WindowContext.Consumer>
            {
                (context) =>
                    <HomeDataWrapper
                        {...props}
                        {...context}
                    />
            }
        </WindowContext.Consumer>
    );
}


const HomeDataWrapper = props => {
    const {lng} = props.match.params;
    const {loading, error, data} = useQuery(
        QUERY,
        {
            variables: {
                siteId: decidePageId(lng),
            }
        }
    );
    useEffect(() => {
        if (loading !== props.gettingData) {
            props.setGettingData(loading);
        }
    }, [props, loading]);
    if (loading) return <div className="Page-Loader"><Loader/></div>;
    if (error) return <p>Error :(</p>;
    return <HomeView {...data} {...props} />
}